.pagination {
    /* margin: 15px auto; */
    display: flex;
    list-style: none;
    outline: none;
  }
  .pagination > .active > a{
    background-color: #049eff!important;
    color: #fff !important;
  }
  .pagination > li > a{
    padding: .375rem .75rem;
    outline: none;
    cursor: pointer;
  }
  .pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
    background-color: #049eff ;
    outline: none ;
  }
  .pagination > .disabled > a{
    color: #666 !important;
  }
  .pagination > li > a, .pagination > li > span{
    position: relative;
    display: block;
    color: #0d6efd;
    text-decoration: none;
    color: #0d6efd !important;
    background-color: #fff !important;
    border: 1px solid #909ca8 !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    margin-left: -1px;
    font-size: 14px;
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span{
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }
  .pagination > li:last-child > a, .pagination > li:last-child > span{
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }
.modal .login-req svg {
	margin-bottom: 20px;
}
.modal .login-req  .bi-cancel{
	margin-bottom: 0 !important;
}
.modal .login-req svg .a,
.modal .login-req svg .b {
	fill: var(--secondary);
}

.modal .login-req svg .b {
	fill-rule:evenodd;
}

.modal .login-req h1 {
	font-size: 30px;
	font-weight: 600;
	margin-bottom: 30px;
}

.modal .login-req ul li .btn {
	/* width: 120px !important; */
	font-weight: 600;
	min-width:120px !important;
}
.modal .login-req ul li .naviagte-btn {
	/* width: 120px !important; */
	font-weight: 600;
	min-width:120px !important;
	color: var(--secondary) !important;
	background-color: #fff !important;
}
.modal .login-req ul li .naviagte-btn:hover {
	/* width: 120px !important; */
	font-weight: 600;
	min-width:120px !important;
	color: #fff !important;
	background-color: var(--secondary) !important;
	border:2px solid var(--secondary) !important
}

.modal .login-req ul li .btn.signup-btn {
	background-color: #fff !important;
	color: #000 !important;
	box-shadow: 0px 3px 6px #00000029;
}

.modal .login-req ul li .btn.signup-btn:hover {
	background-color: #f7f7f7 !important;
	box-shadow: 0px 3px 6px #00000029 !important;
}